let data = {
    "3d Graphics": {
        "Description": 
           `<p>I have done some 3d graphics programming!</p>
           <p>This was done in a website called shadertoy</p>
           <p>You can see some of my work <a href="https://www.shadertoy.com/results?query=phllpbll189">here</a>`,
        "img": "normalSphere.png",
        "bgPosition": "center",
        "alt": "a sphere with normal maps"
    } 
}

export default data